<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Edit page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.elements.title_ua">
                            <el-input v-model="Pages.data.elements.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h1> Page Top </h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog1.title_ua">
                            <el-input v-model="Pages.data.blog1.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog1.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog1.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Button title</p>
                        <el-form-item prop="data.blog1.title_ua">
                            <el-input v-model="Pages.data.blog1.button_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog1Image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 1 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'blog1Image1')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog1Image1&&Pages.files.blog1Image1.name? Pages.files.blog1Image1.name: Pages.files.blog1Image1 ? Pages.files.blog1Image1 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog1Image2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 2 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'blog1Image2')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog1Image2&&Pages.files.blog1Image2.name? Pages.files.blog1Image2.name: Pages.files.blog1Image2 ? Pages.files.blog1Image2 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog1Image3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 3 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'blog1Image3')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog1Image3&&Pages.files.blog1Image3.name? Pages.files.blog1Image3.name: Pages.files.blog1Image3 ? Pages.files.blog1Image3 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog1Cloud">
                            <div class="upload-image">
                                <button class="bg-red">
                                     Upload Clouds
                                    <input type="file" name="image" @change="UploadIMage($event ,'blog1Cloud')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog1Cloud&&Pages.files.blog1Cloud.name? Pages.files.blog1Cloud.name: Pages.files.blog1Cloud ? Pages.files.blog1Cloud :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div> 
                    <h1> Page Linkes </h1>
                    <div class="item mt-20">
                        <p>1.Title</p>
                        <el-form-item prop="data.blog2.title1_ua">
                            <el-input v-model="Pages.data.blog2.title1_ua"></el-input>
                        </el-form-item>
                    </div>
                     
                    <div class="item">
                        <el-form-item prop="files.blog2Image1">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog2Image1')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog2Image1&&Pages.files.blog2Image1.name? Pages.files.blog2Image1.name:Pages.files.blog2Image1? Pages.files.blog2Image1:'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>2.Title</p>
                        <el-form-item prop="data.blog2.title2_ua">
                            <el-input v-model="Pages.data.blog2.title2_ua"></el-input>
                        </el-form-item>
                    </div>
                    
                    <div class="item">
                        <el-form-item prop="files.blog2Image2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event, 'blog2Image2')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog2Image2&&Pages.files.blog2Image2.name? Pages.files.blog2Image2.name: Pages.files.blog2Image2 ? Pages.files.blog2Image2 : 'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>3.Title</p>
                        <el-form-item prop="data.blog2.title3_ua">
                            <el-input v-model="Pages.data.blog2.title3_ua"></el-input>
                        </el-form-item>
                    </div>
                     
                    <div class="item">
                        <el-form-item prop="files.blog2Image3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog2Image3')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog2Image3&&Pages.files.blog2Image3.name? Pages.files.blog2Image3.name: Pages.files.blog2Image3 ? Pages.files.blog2Image3 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>4.Title</p>
                        <el-form-item prop="data.blog2.title4_ua">
                            <el-input v-model="Pages.data.blog2.title4_ua"></el-input>
                        </el-form-item>
                    </div>
                     
                    <div class="item">
                        <el-form-item prop="files.blog2Image4">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog2Image4')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog2Image4&&Pages.files.blog2Image4.name? Pages.files.blog2Image4.name:Pages.files.blog2Image4 ? Pages.files.blog2Image4 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                    <h1>Credit</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog3.title_ua">
                            <el-input v-model="Pages.data.blog3.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Button title</p>
                        <el-form-item prop="data.blog3.title_ua">
                            <el-input v-model="Pages.data.blog3.button_ua"></el-input>
                        </el-form-item>
                    </div>

                    <h1>Stock</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog4.title_ua">
                            <el-input v-model="Pages.data.blog4.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog4.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog4.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Button title</p>
                        <el-form-item prop="data.blog4.title_ua">
                            <el-input v-model="Pages.data.blog4.button_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog3.button_en">
                            <el-input v-model="Pages.data.blog4.url"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Leasing</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog5.title_ua">
                            <el-input v-model="Pages.data.blog5.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog5.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog5.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Text</p>
                        <el-form-item prop="data.blog5.text_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog5.text_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>1.Button title</p>
                        <el-form-item prop="data.blog5.button1_ua">
                            <el-input v-model="Pages.data.blog5.button1_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>1.Button url</p>
                        <el-form-item prop="data.blog5.button1_ua">
                            <el-input v-model="Pages.data.blog5.button1_url"></el-input>
                        </el-form-item>
                    </div>
                    
                    <div class="item mt-20">
                        <p>2.Button title</p>
                        <el-form-item prop="data.blog5.button2_ua">
                            <el-input v-model="Pages.data.blog5.button2_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>2.Button url</p>
                        <el-form-item prop="data.blog5.button1_ua">
                            <el-input v-model="Pages.data.blog5.button2_url"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog5Image1">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 1 Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog5Image1')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog5Image1&&Pages.files.blog5Image1.name? Pages.files.blog5Image1.name:Pages.files.blog5Image1?Pages.files.blog5Image1 : 'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog5Image2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 2 Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog5Image2')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog5Image2&&Pages.files.blog5Image2.name? Pages.files.blog5Image2.name:Pages.files.blog5Image2?Pages.files.blog5Image2 : 'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog5Image3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 3 Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog5Image3')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog5Image3&&Pages.files.blog5Image3.name? Pages.files.blog5Image3.name:Pages.files.blog5Image3?Pages.files.blog5Image3 : 'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog5Cloud">
                            <div class="upload-image">
                                <button class="bg-red">
                                     Upload Clouds
                                    <input type="file" name="image" @change="UploadIMage($event,'blog5Cloud')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog5Cloud&&Pages.files.blog5Cloud.name? Pages.files.blog5Cloud.name:Pages.files.blog5Cloud?Pages.files.blog5Cloud : 'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>

                    <h1>Benefits</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog6.title_ua">
                            <el-input v-model="Pages.data.blog6.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog6.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog6.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog6.button_ua">
                            <el-input v-model="Pages.data.blog6.button_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button Link</p>
                        <el-form-item prop="data.blog6.button_ua">
                            <el-input v-model="Pages.data.blog6.button_url"></el-input>
                        </el-form-item>
                    </div>
                    
                    <div class="item mt-20">
                        <p>1. Title</p>
                        <el-form-item prop="data.blog6.title1_ua">
                            <el-input v-model="Pages.data.blog6.title1_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog6.url1">
                            <el-input v-model="Pages.data.blog6.url1"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog6Image1">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog6Image1')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog6Image1&&Pages.files.blog6Image1.name? Pages.files.blog6Image1.name:Pages.files.blog6Image1?Pages.files.blog6Image1:'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>2. Title</p>
                        <el-form-item prop="data.blog6.title2_ua">
                            <el-input v-model="Pages.data.blog6.title2_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog6.url2">
                            <el-input v-model="Pages.data.blog6.url2"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog6Image2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog6Image2')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog6Image2&&Pages.files.blog6Image2.name? Pages.files.blog6Image2.name: Pages.files.blog6Image2 ? Pages.files.blog6Image2:'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>3. Title</p>
                        <el-form-item prop="data.blog6.title3_ua">
                            <el-input v-model="Pages.data.blog6.title3_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog6.url3">
                            <el-input v-model="Pages.data.blog6.url3"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog6Image3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog6Image3')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog6Image3&&Pages.files.blog6Image3.name? Pages.files.blog6Image3.name:Pages.files.blog6Image3? Pages.files.blog6Image3 :'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>4. Title</p>
                        <el-form-item prop="data.blog6.title4_ua">
                            <el-input v-model="Pages.data.blog6.title4_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog6.url4">
                            <el-input v-model="Pages.data.blog6.url4"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog6Image4">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog6Image4')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog6Image4&&Pages.files.blog6Image4.name? Pages.files.blog6Image4.name:Pages.files.blog6Image4? Pages.files.blog6Image4:'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>5. Title</p>
                        <el-form-item prop="data.blog6.title5_ua">
                            <el-input v-model="Pages.data.blog6.title5_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.blog6.url5">
                            <el-input v-model="Pages.data.blog6.url5"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog6Image5">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog6Image5')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog6Image5&&Pages.files.blog6Image5.name? Pages.files.blog6Image5.name:Pages.files.blog6Image5?Pages.files.blog6Image5:'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                    <h1>About Us</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog7.title_ua">
                            <el-input v-model="Pages.data.blog7.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog7.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog7.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog6.button_ua">
                            <el-input v-model="Pages.data.blog7.button_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button Link</p>
                        <el-form-item prop="data.blog6.button_ua">
                            <el-input v-model="Pages.data.blog7.button_url"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.blog7Image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event,'blog7Image')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.blog7Image&&Pages.files.blog7Image.name? Pages.files.blog7Image.name: Pages.files.blog7Image ? Pages.files.blog7Image: 'Upload Image'}} </p>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.elements.title_en">
                            <el-input v-model="Pages.data.elements.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1> Page Top </h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog1.title_en">
                            <el-input v-model="Pages.data.blog1.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog1.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog1.description_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog1.button_en">
                            <el-input v-model="Pages.data.blog1.button_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1> Page Linkes </h1>
                    <div class="item mt-20">
                        <p>1.Title</p>
                        <el-form-item prop="data.blog2.title1_en">
                            <el-input v-model="Pages.data.blog2.title1_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>2.Title</p>
                        <el-form-item prop="data.blog2.title2_en">
                            <el-input v-model="Pages.data.blog2.title2_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>3.Title</p>
                        <el-form-item prop="data.blog2.title3_en">
                            <el-input v-model="Pages.data.blog2.title3_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>4.Title</p>
                        <el-form-item prop="data.blog2.title4_en">
                            <el-input v-model="Pages.data.blog2.title4_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Credit</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog3.title_en">
                            <el-input v-model="Pages.data.blog3.title_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog3.button_en">
                            <el-input v-model="Pages.data.blog3.button_en"></el-input>
                        </el-form-item>
                    </div>
                    

                    <h1>Stock</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog4.title_en">
                            <el-input v-model="Pages.data.blog4.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog4.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog4.description_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog5.button_en">
                            <el-input v-model="Pages.data.blog4.button_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Leasing</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog5.title_en">
                            <el-input v-model="Pages.data.blog5.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog5.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog5.description_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Text</p>
                        <el-form-item prop="data.blog5.text_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog5.text_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>1.Button title</p>
                        <el-form-item prop="data.blog5.button1_en">
                            <el-input v-model="Pages.data.blog5.button1_en"></el-input>
                        </el-form-item>
                    </div>
                    
                     <div class="item mt-20">
                        <p>2.Button title</p>
                        <el-form-item prop="data.blog5.button2_en">
                            <el-input v-model="Pages.data.blog5.button2_en"></el-input>
                        </el-form-item>
                    </div>

                    <h1>Benefits</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog6.title_en">
                            <el-input v-model="Pages.data.blog6.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog6.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog6.description_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog6.button_en">
                            <el-input v-model="Pages.data.blog6.button_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>1. Title</p>
                        <el-form-item prop="data.blog6.title1_en">
                            <el-input v-model="Pages.data.blog6.title1_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>2. Title</p>
                        <el-form-item prop="data.blog6.title2_en">
                            <el-input v-model="Pages.data.blog6.title2_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>3. Title</p>
                        <el-form-item prop="data.blog6.title3_en">
                            <el-input v-model="Pages.data.blog6.title3_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>4. Title</p>
                        <el-form-item prop="data.blog6.title4_en">
                            <el-input v-model="Pages.data.blog6.title4_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>5. Title</p>
                        <el-form-item prop="data.blog6.title5_en">
                            <el-input v-model="Pages.data.blog6.title5_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>About Us</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.blog7.title_en">
                            <el-input v-model="Pages.data.blog7.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.blog7.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.blog7.description_en"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.blog7.button_en">
                            <el-input v-model="Pages.data.blog7.button_en"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div>
            </el-form> 
        </div>
    </div>  

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/",
                data: {
                    elements: {
                        title_ua: '',
                        title_en: '', 
                    },
                    blog1: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        button_ua:'',
                        button_en:''
                    },
                    blog2: {
                        status: false,
                        title1_ua: '',
                        title1_en: '', 
                        title2_ua: '',
                        title2_en: '', 
                        title3_ua: '',
                        title3_en: '', 
                        title4_ua: '',
                        title4_en: '', 
                    },
                    blog3: {
                        title_ua: '',
                        title_en: '',
                        button_ua:'',
                        button_en:''
                    },
                    blog4: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        button_ua:'',
                        button_en:'',
                        url:''

                    },
                    blog5: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        text_ua: '',
                        text_en: '',
                        button1_ua:'',
                        button1_en:'',
                        button1_url:'',
                        button2_ua:'',
                        button2_en:'',
                        button2_url:''

                    },
                    blog6: {
                        title_ua: '',
                        title_en: '',
                        title1_ua: '',
                        title1_en: '',
                        url1: '',
                        title2_ua: '',
                        title2_en: '',
                        url2: '',
                        title3_ua: '',
                        title3_en: '',
                        url3: '',
                        title4_ua: '',
                        title4_en: '',
                        url4: '',
                        title5_ua: '',
                        title5_en: '',
                        url5: '',
                        button_ua:'',
                        button_en:'',
                        button_url:'#'
                    },
                    blog7: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        button_ua:'',
                        button_en:'',
                        button_url:'#'
                    }

                },
                files: {
                    blog1Image1: '',
                    blog1Image2: '',
                    blog1Image3: '',
                    blog1Cloud:'', 
                    blog2Image1: '',
                    blog2Image2: '',
                    blog2Image3: '',
                    blog2Image4: '',
                    blog6Image1: '',
                    blog6Image2: '',
                    blog6Image3: '',
                    blog6Image4: '',
                    blog6Image5: '',
                    blog5Image1: '',
                    blog5Image2: '',
                    blog5Image3: '',
                    blog5Cloud: '',
                    blog7Image: '',
                    siteLogo_ua:'',
                    siteLogo_en:''
                }
            },
            PagesRules: {
                data: {
                    elements: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        name: 'home'
                    },
                    blog1: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    },
                    blog2: {
                        status: false,
                        title1_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title1_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url1: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title2_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title2_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url2: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title3_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title3_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url3: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title4_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title4_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url4: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    },
                    blog3: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    },
                    blog4: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],

                    },
                    blog5: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        text_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        text_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],

                    },
                    blog6: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title1_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title1_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url1: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title2_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title2_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url2: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title3_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title3_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url3: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title4_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title4_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url4: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title5_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title5_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        url5: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    },
                    blog7: {
                        title_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        title_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_ua: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                        description_en: [{
                            required: true,
                            message: 'Будь ласка, введіть інформацію',
                            trigger: 'blur'
                        }],
                    }

                },
                files: {
                    blog1Image1: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog1Image2: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog1Image3: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog2Image1: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog2Image2: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog2Image3: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog2Image4: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog6Image1: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog6Image2: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog6Image3: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog6Image4: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog6Image5: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog5Image: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    blog7Image: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                }
            },
        }

    },
 
    methods: {
        UploadIMage(event, name) {
            this.Pages.files = {
                ...this.Pages.files,
                [name]: event.target.files[0]
            }
        },
        PostStock() {  
            this.$refs.pagesForm.validate((valid) => { 
                if (valid) { 
                    this.loading = true
                    let files = new FormData();
                    var pageData={}
                    if(typeof this.Pages.data != 'string'){  
                        pageData = JSON.stringify(this.Pages.data)
                    }
                    files.append('key', this.Pages.key)
                    files.append('data', pageData)
                    for (let i of Object.keys(this.Pages.files)) {
                        if (this.Pages.files[i].name) {
                            files.append(`files[${i}]`, this.Pages.files[i]);
                            console.log(i)
                        }
                    }

                    this.axios.post(`/admin/statpage/${this.Pages.id}`, files).then(res => {
                            console.log(res)
                            this.$router.push('/admin/statpages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                            this.$store.dispatch("get_statpages");
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        }) 
                        .finally(()=>{
                            this.loading=false
                        })
                } else {
                    console.log("AAA")
                }
            })

        },
        GetPages() {
            this.loading=true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
